import { DeliveryAddressSearchVersionType } from '../../address'

type MenuPage = Bff.Menu.old.MenuPage

export type AnalyticEvents = AnalyticsEventCallbacks
export type AnalyticEventNames = keyof AnalyticEvents
export type AnalyticSender = <K extends AnalyticEventNames>(
  name: K,
  data?: AnalyticEvents[K],
  callback?: () => void,
) => {} | void

interface AnalyticsEventCallbacks {
  'Page Loaded': PageViewEvent
  'Page View': PageViewEvent
  'Payment Method': PaymentMethod
  'Validation Message': ValidationMessageEvent
  'EE - Purchase': EnhancedEcommercePurchaseEvent
  'Voucher Code Processed': VoucherCodeDeclinedEvent | VoucherCodeAcceptedEvent
  'Voucher Code Entered': VoucherCodeEntered
  'Voucher Code Removed': VoucherCodeRemoved
  'Payment Error': PaymentErrorEvent
  'Estimated Order Time': PreOrderEstimateEvent
  'Quick Signup Account Creation Success': QuickSignUpAccountEvent
  'Quick Signup Account Creation Failed': QuickSignUpAccountEvent
  'Quick Signup Account Login Success': QuickSignInAccountEvent
  'Quick Signup Account Forgot Password': QuickSignInAccountEvent
  'Quick Signup Account Creation Attempt': QuickSignUpAccountAttemptEvent
  'AutoComplete Address Search': AutoCompleteEvent
  add_to_cart: AnalyticsItemsParams
  remove_from_cart: AnalyticsItemsParams
  side_added_from_hardupsell: HardUpsellActionEvent
  hardupsell_skip: HardUpsellActionEvent
  hardupsell_initialised: HardUpsellActionEvent
  premium_upsell_added: ProductUpsellEvent
  premium_upsell_removed: ProductUpsellEvent
  premium_upsell_enabled: ProductUpsellEvent
  portion_swap: PortionSwapEvent
  create_halfhalf: CreateHalfHalfEvent
  'Returning Customer Order Details': CustomerOrderDetailsEvent
  'Banner Interaction': BannerInteractionEvent
  interaction: InteractiveEvent
}
export type FeatureEventSender = (eventName: string, metricValue?: number) => Promise<void>

type PageViewEvent = Environment &
  Page &
  User &
  Market &
  Platform &
  Language &
  Store &
  ServiceMethod &
  OrderView &
  PaymentMethod &
  EcommerceCart &
  Loyalty &
  PageLoadStats &
  DeliveryAddressSearch

interface ValidationMessageEvent {
  'Validation Message': string
  'Validation Message Severity Level'?: string
  'Validation Message Source'?: string
  'Validation Message Code'?: string
}

export interface EnhancedEcommercePurchaseEvent {
  'Reported Via ServerSide': false
  Order: Order
  Payment?: PaymentMethod
  'Checkout Hashed Email'?: string
  'Checkout Hashed Phone'?: string
}

export type VoucherCodeSource = 'Form' | 'Link' | 'Offers'
export type VoucherProcessingStatus = 'Accepted' | 'Unknown' | 'Expired' | 'Not valid'

interface VoucherCodeEntered {
  'Voucher Code': string
  'Voucher Code Source': VoucherCodeSource
}
interface VoucherCodeRemoved {
  'Voucher Code': string
  'Voucher Code Source': VoucherCodeSource
  'Voucher Value'?: number
}
interface VoucherCodeAcceptedEvent {
  'Voucher Code': string
  'Voucher Processing Status': VoucherProcessingStatus
  'Voucher Description'?: string
  'Voucher Value'?: number
}
export interface VoucherCodeDeclinedEvent {
  'Voucher Processing Status': VoucherProcessingStatus
  'Voucher Code'?: string
  'Voucher Code Source'?: VoucherCodeSource
  'Voucher Declined Reason'?: string
}
export interface PaymentErrorEvent {
  'Payment Error Title': string
  'Payment Error Message': string
  'Payment Error Code': string
}

interface PreOrderEstimateEvent {
  Action: BffContext.ServiceMethods
  Label: `${number}-${number}`
}

interface Environment {
  environment: 'prod' | 'staging'
}

interface Page {
  'Full Page Url': string
  'Page Title': string
}

interface User {
  'User Id'?: string
  'User Hashed Email'?: string
  'User Hashed Phone'?: string
  'App Device Id'?: string
}

interface Market {
  Market: BffContext.Countries
}

interface Platform {
  Platform: 'app' | 'web' | 'kiosk'
}

interface Language {
  'UI Language': `${Uppercase<BffContext.Languages>}`
}

interface Store {
  'Store Name'?: string
  'Store Id'?: number
}

interface ServiceMethod {
  'Service Method'?: BffContext.ServiceMethods
  'Service Method Subtype'?: Bff.Stores.ServiceMethodSubTypes
  'Service Method Timing'?: 'ASAP' | number
}

export interface PaymentMethod {
  'Store Name'?: string
  'Store Id'?: number
  'Service Method'?: BffContext.ServiceMethods
  'Service Method Subtype'?: Bff.Stores.ServiceMethodSubTypes
  'Payment Provider'?: BffContext.PaymentProviders
  'Payment Method'?: BffContext.PaymentMethods
}

interface OrderView {
  Order?: Partial<Pick<Order, 'Id'>>
}

export interface EcommerceCart {
  'Ecommerce Cart'?: {
    Total: number
    'Total As Integer': number
    Products?: Product[]
  }
}

export type Currency = 'AUD' | 'NZD' | 'EUR' | 'JPY' | 'DKK' | 'SGD' | 'MYR' | 'KHR' | 'TWD'

export interface Product {
  name: string
  id: string
  price: string
  currency?: Currency
  category?: MenuPage['code']
  quantity: number
  inStock: boolean
  crust?: Ingredient['code']
  sauce?: Ingredient['code']
  size?: ProductSize['code']
  variant?: string
  modifications?: string[]
  coupon?: string
}

interface Loyalty {
  'Loyalty Program Status'?: string
  'Loyalty Program User Points'?: string
  'Loyalty Program User Points Eligible'?: string
  'Loyalty Program Order Points'?: string
  'Loyalty Program Order Points Eligible'?: string
}

interface DeliveryAddressSearch {
  deliveryAddressSearchVersion?: DeliveryAddressSearchVersionType
}

interface PageLoadStats {
  'Time Taken'?: number
  'Page Timestamp': number
}

export interface Order {
  Id: string
  Affiliation: Store['Store Id']
  Currency: Currency
  Revenue: number
  Tax?: number
  Shipping?: number
  Coupon?: string
  Products: Product[]
}

export type SignUpMethod = 'email' | 'apple' | 'facebook' | 'google'

export interface QuickSignUpAccountEvent {
  'User Id': string | undefined
  create_account_loyalty_optin: boolean | undefined
  signup_method: SignUpMethod
  signup_platform: 'Web'
  signup_location?: string
  error_message: string | undefined
}

export interface QuickSignUpAccountAttemptEvent {
  loyalty_checked: boolean
  signup_method: SignUpMethod
  signup_platform: 'Web'
  signup_location?: string
}

export interface QuickSignInAccountEvent {
  login_method: SignUpMethod
  login_platform: 'Web'
  login_location?: string
  'User Id'?: string
}

export enum UpsellFeatureType {
  inline_basket = 'inline_basket_upsell',
  hard_upsell = 'hard_checkout_upsell',
}

export interface AnalyticsItemsParams {
  items: SecurityProductParams[]
  upsell_feature?: UpsellFeatureType
}

export interface CustomerOrderDetailsEvent {
  Action: string
}

export interface BannerInteractionEvent {
  event: string
  element_type: string
  element_id: string
  element_name: string
  element_source: string
  interaction_type: string
  items?: string
}

export interface AutoCompleteEvent {
  event: string
  status: 'address found' | 'address not found'
}

export interface HardUpsellActionEvent {
  event: string
  platform: BffContext.ApplicationTypes
  items?: SecurityProductParams[]
}

export interface ProductUpsellEvent {
  event: 'premium_upsell_added' | 'premium_upsell_removed' | 'premium_upsell_enabled'
}

export interface PortionSwapEvent {
  parent_item_id: string
  swap_position: number
  swapped_in_item_id: string | undefined
  swapped_out_item_id: string | undefined
}

export interface CreateHalfHalfEvent {
  from_item_id: string
  to_item_id: string
}

export interface InteractiveEvent {
  label: string
  value_string?: string
}
