import { isPortionMenuItem, isProductMenuItem } from '@dominos/components'

type ProductTypeCode = Bff.Common.ProductTypeCode

const getTypeFromProduct = (
  legacyProduct:
    | (Bff.Menu.old.ProductMenuItem & { category?: string })
    | (Bff.Menu.old.PortionMenuItem & { category?: string })
    | null,
): ProductTypeCode | undefined => {
  if (!legacyProduct) return undefined
  if (isProductMenuItem(legacyProduct)) {
    const swaps = legacyProduct.sizes?.[0]?.swaps

    if (swaps?.bases || swaps?.sauces || swaps?.toppings) {
      return 'Recipe'
    }

    if (swaps?.options) {
      return 'Option'
    }

    return 'Simple'
  }
  if (isPortionMenuItem(legacyProduct)) {
    return 'Portion'
  }
}

export { getTypeFromProduct }
