import { CollapsableTitledCard, Portion, PortionTile } from '@dominos/components'
import React from 'react'
import { ProductPortionsProps } from './product-portions.interface'
import css from './product-portions.less'

const isPortionsLengthValid = (portions: Portion[] | undefined) =>
  !portions || (portions.length !== 2 && portions.length !== 4) ? false : true

const ProductPortions = ({
  t,
  testID,
  portions,
  products,
  onPortionProductChange,
  customisable,
  onPortionReset,
  onPortionCustomise,
  isKioskOrder,
}: ProductPortionsProps) => (
  <>
    {isPortionsLengthValid(portions) && (
      <div data-testid={`${testID}.portion-wrapper`} className={css.headerContainer}>
        <CollapsableTitledCard
          testID={`product-portions`}
          primaryTitle={''}
          static={true}
          startCollapsed={false}
          noPadding={true}
        >
          <div className={css.portionsContainer}>
            {portions?.map((portion, index) => (
              <div key={index} className={css.portionContainer}>
                <PortionTile
                  t={t}
                  portionIndex={index as PortionIndex}
                  portionCount={portions.length as PortionCount}
                  products={products}
                  selectedProductCode={portion.productCode}
                  disabled={!portion.canSwap}
                  showReset={portion.isSwapped}
                  onSelectChange={(code) => onPortionProductChange(index, code)}
                  customisable={customisable}
                  onReset={() => onPortionReset(index)}
                  onCustomise={() => onPortionCustomise(index)}
                  isKioskOrder={isKioskOrder}
                />
              </div>
            ))}
          </div>
        </CollapsableTitledCard>
      </div>
    )}
  </>
)

export { isPortionsLengthValid, ProductPortions }
