import React from 'react'
import { IUseDeliveryPreviewListContainerProps, useDeliveryPreviewListContainer } from 'olo-feature-address'
import { ListGroup } from '@dominos/components/lists/list-group/list-group'

export type DeliveryPreviewListContainerProps = IUseDeliveryPreviewListContainerProps

export const DeliveryPreviewListContainer = (props: DeliveryPreviewListContainerProps) => {
  const { deliveryAddressListGroup, showDeliveryPreviewList, onDeliveryAddressSelect } =
    useDeliveryPreviewListContainer(props)

  if (!showDeliveryPreviewList) return <></>

  return (
    <ListGroup
      testID={'delivery-preview-list'}
      data={deliveryAddressListGroup}
      onItemSelect={onDeliveryAddressSelect}
      itemLoadingKey={''}
      hideBorderAboveFirstGroup={true}
      iconStyling={{ width: 30, height: 30 }}
    />
  )
}
