import { CardHeader, CreateAccount } from '@dominos/components'
import { useAuthenticationPopup, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import { isNativeApp, sendTokenToNativeApp } from '@dominos/business/functions/native-app'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import css from './register-email.less'
import { NavigationConstants } from '@dominos/navigation'
import { RegisterEmailProps, StateCustomerData } from './register-email.interface'

export const RegisterEmail = ({ testID }: RegisterEmailProps) => {
  const { t } = useTranslation('login')

  const { triggerAction, hasPrevious, previousStep, data } = useQuickUniversalAccountComponent()
  const { hidePopup } = useAuthenticationPopup()
  const isNative = isNativeApp()

  const handlePrevious = useCallback(() => previousStep(), [])

  const handleLogin = useCallback(() => triggerAction('login', { ...data, socialCustomer: undefined }), [])

  const handleSuccess = useCallback((idToken: string) => {
    if (isNative) {
      sendTokenToNativeApp(idToken, NavigationConstants.nativeAppCreateAccount)
    }

    hidePopup('SignUpSuccess')
  }, [])

  const handleFailure = useCallback(() => triggerAction('failed'), [])

  const customer = useMemo(() => {
    const { customer, socialCustomer } = data as Partial<StateCustomerData>

    return { ...customer, ...socialCustomer }
  }, [data])

  return (
    <div data-testid={testID} className={css.wrapper}>
      <CardHeader
        testID={`${testID}.header`}
        title={t('SingUpEmailTitle', { defaultValue: `Create Account` })}
        onAction={hasPrevious ? handlePrevious : undefined}
      />
      <CreateAccount
        testID={`${testID}.create`}
        name={customer?.name}
        alternateName={customer?.alternateName}
        email={customer?.email}
        phoneNumber={customer?.phoneNumber}
        identityProvider={customer?.identityProvider}
        authenticationSource={'UniversalAuth'}
        onLogin={handleLogin}
        onSuccess={handleSuccess}
        onFail={handleFailure}
      />
    </div>
  )
}
