import gql from 'graphql-tag'

export const kioskConfigQuery = gql`
  query kioskConfig {
    kioskConfig {
      storeNo
      kioskId
      countryCode
      terminalId
      supportedSubServiceMethods
    }
  }
`
