import React, { useMemo } from 'react'
import { CollapsableTitledCard } from '../../cards'
import { useTranslation } from 'react-i18next'
import css from './inline-single-item-offer.less'
import { useBasket } from '@dominos/hooks-and-hocs'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { findProductsInBasket, LineItem, usePopupContent, useSingleItemOffer } from './functions'
import { RadioInputField } from '../../inputs/radio-input-field/radio-input-field'
import { MoreInfo } from '@dominos/components/more-info'
interface InlineSingleItemOfferProps extends BaseProps {
  filterLocation?: Partial<Bff.Offers.Location>
}

export const InlineSingleItemOffer = ({ testID, filterLocation = {} }: InlineSingleItemOfferProps) => {
  const { t } = useTranslation('checkout')

  const matchFunction = (offer: Bff.Offers.Offer) =>
    offer.locations.some(
      (location) =>
        location.locationType === filterLocation.locationType &&
        location.locationCode === filterLocation.locationCode &&
        location.showBanner === filterLocation.showBanner,
    )
  const { loading, offer } = useSingleItemOffer(matchFunction)
  const dispatch = useDispatch()
  const { basket } = useBasket()
  const basketOffer = useMemo(
    () =>
      findProductsInBasket(
        offer?.items.map((item) => item.linkedItem.itemCode),
        basket,
      ),
    [offer, basket],
  )
  const itemCode = offer?.items[0].linkedItem.itemCode
  const selectedOption = useMemo<string | number>(() => {
    if (itemCode && basketOffer?.productCode === itemCode) {
      return itemCode
    }

    return 0
  }, [basketOffer?.productCode, itemCode])
  const popupContent = usePopupContent(offer)

  const handleSelection = (name: string, code: string | number) => {
    if ('string' === typeof code && offer?.items.some((item) => code === item.linkedItem.itemCode)) {
      const lineItemToAdd = new LineItem(code, name)
      if (!basketOffer) {
        dispatch(rootActions.addLinesToBasket({ add: [lineItemToAdd] }))
      } else {
        dispatch(rootActions.replaceBasketLine({ remove: basketOffer, add: lineItemToAdd }))
      }
    } else if (basketOffer) {
      dispatch(rootActions.removeLineFromBasket(basketOffer))
    }
  }

  if (!offer) {
    return null
  }

  return (
    <CollapsableTitledCard testID={testID} primaryTitle={offer.media.name} style={{ marginTop: 10 }}>
      <fieldset className={css.options} key={selectedOption} id={`${testID}-${offer.offerId}`}>
        <div className={css.option}>
          <RadioInputField
            testID={`${testID}.opt-out`}
            fieldName={'OptOut'}
            value={0}
            fieldId={testID}
            onChecked={handleSelection}
            selectedOption={selectedOption}
            disabled={loading}
            labelText={t(`CheckoutOfferOptOut`, `No thanks`)}
          />
        </div>
        {offer.items.map((item) => (
          <div className={css.option} key={item.id}>
            <RadioInputField
              testID={`${testID}.offer-item`}
              fieldName={item.name}
              value={item.linkedItem.itemCode}
              fieldId={item.id as unknown as string}
              onChecked={handleSelection}
              selectedOption={selectedOption}
              disabled={loading}
              labelText={t(`CheckoutOfferOptIn`, `Yes, add {{productName}} (+{{price}} more)`, {
                price: item.price,
                productName: item.name,
              })}
            />
            <MoreInfo
              testID={`${testID}.info`}
              id={'single-item-offer'}
              label={t('learnMoreButton', { defaultValue: 'Learn More' })}
              title={t('CheckoutOfferPopupTitle', { defaultValue: `What is this?` })}
              description={popupContent.description}
              media={{ src: popupContent.imageURL, alt: popupContent.altText }}
            />
          </div>
        ))}
      </fieldset>
    </CollapsableTitledCard>
  )
}
