import {
  BaseProductEditor,
  BottomBar,
  HalfHalfButtonCard,
  ProductCustomiser,
  useProductContext,
} from '@dominos/components'
import { createBasketLineSwap, useBaseData, useSauceData, useSizeData } from '@dominos/components/product/functions'
import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import ProductBuildYourOwn from '../product-build-your-own/product-build-your-own'
import { RecipeProductCardProps } from './recipe-product-card.interface'

export const RecipeProductCard = ({ testID, addToBasket, onDismiss, isEditing, t }: RecipeProductCardProps) => {
  const theme = useDominosTheme()
  const [productBuildYourOwnEnabled] = useFeatures('ProductBuildYourOwnEnabled')
  const {
    dimensionSetState,
    productData,
    saveBasketLineDataChanges,
    currentBasketLineData,
    createBasketLine,
    initialQuantity,
    switchProduct,
  } = useProductContext()

  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)

  const sauceIngredients = productData?.getIngredients('Sauce', dimensionSetState?.selectedDimensionSet) || []
  const sauceRules = productData?.getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const defaultSauce = sauceIngredients?.find((ingredient) => ingredient.inRecipe === true)
  const sauceData = useSauceData(sauceIngredients, currentBasketLineData?.sauce, sauceRules?.minQuantity === 0)

  const isBuildYourOwnHalfHalfEnabled = productData?.features?.buildYourOwnHalfHalf?.isEnabled || false
  const isBuildYourOwnQuattroEnabled = productData?.features?.buildYourOwnQuattro?.isEnabled || false
  const isBuildYourOwnEnabled =
    productBuildYourOwnEnabled && (isBuildYourOwnHalfHalfEnabled || isBuildYourOwnQuattroEnabled)

  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onSauceChange = (sauceCode: string) => {
    saveBasketLineDataChanges('sauce', createBasketLineSwap(sauceCode, defaultSauce?.code))
  }
  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  const handleBuildYourOwn = (targetProductCode: string | undefined) => {
    productData?.code && targetProductCode && switchProduct(targetProductCode, productData?.code)
  }

  return (
    <>
      <BaseProductEditor testID={`${testID}.base-product-editor`} onDismiss={onDismiss} t={t}>
        {isBuildYourOwnEnabled ? (
          <ProductBuildYourOwn
            t={t}
            color={theme.colours.actionBlue}
            testID={`${testID}.product-build-your-own`}
            isCreateHalfHalfEnabled={isBuildYourOwnHalfHalfEnabled}
            onCreateHalfHalfClick={() =>
              handleBuildYourOwn(productData?.features?.buildYourOwnHalfHalf?.settings?.productCode)
            }
            isCreateQuattroEnabled={isBuildYourOwnQuattroEnabled}
            onCreateQuattroClick={() =>
              handleBuildYourOwn(productData?.features?.buildYourOwnQuattro?.settings?.productCode)
            }
          />
        ) : (
          isBuildYourOwnHalfHalfEnabled && (
            <HalfHalfButtonCard
              buttonTitle={t('CreateHalfHalfButton')}
              testID={`${testID}.half-half-button-card`}
              onClick={() => handleBuildYourOwn(productData?.features?.buildYourOwnHalfHalf?.settings?.productCode)}
            />
          )
        )}
        <ProductCustomiser
          sizeData={sizeData}
          onSizeChange={onSizeChange}
          baseData={baseData}
          onBaseChange={onBaseChange}
          sauceData={sauceData}
          onSauceChange={onSauceChange}
        />
      </BaseProductEditor>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={false}
        isEditing={isEditing}
      />
    </>
  )
}
