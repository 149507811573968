import { BaseProductEditor, BottomBar, ProductCustomiser, useProductContext } from '@dominos/components'
import { useSizeData } from '@dominos/components/product/functions'
import React from 'react'
import { SimpleProductCardProps } from './simple-product-card.interface'

export const SimpleProductCard = ({ testID, onDismiss, addToBasket, t }: SimpleProductCardProps) => {
  const { dimensionSetState, productData, createBasketLine } = useProductContext()
  const sizeData = useSizeData(productData, dimensionSetState)

  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }

  return (
    <>
      <BaseProductEditor testID={`${testID}.base-product-editor`} onDismiss={onDismiss} t={t}>
        <ProductCustomiser sizeData={sizeData} onSizeChange={onSizeChange} />
      </BaseProductEditor>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        initialQuantity={1}
        isPortionProduct={false}
        forceDisabled={false}
      />
    </>
  )
}
