import { useApolloClient } from '@apollo/client'
import {
  flattenDimensionMedia,
  flattenIngredientMedia,
  flattenMenu,
  setCurrentMenuItems,
} from '@dominos/business/functions/menu'
import { useFeatures, useLanguages, useLocalStorage } from '@dominos/hooks-and-hocs'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { addNewSectionIntoTheMenu } from './add-new-section-into-the-menu-helper'
import { DimensionMediaByCode, IngredientMediaByCode, ItemsByCode, MenuContextData } from './menu-context'
import { useMenuData } from './use-menu-data/use-menu-data'
import { usePortionMenu } from './use-portion-menu'

type Menu = Bff.Menu.old.Menu

const MenuContext = createContext<MenuContextData>({
  data: {},
  methods: { fetchMenu: () => {}, refetchMenu: () => {} },
})

const MenuProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('menu')
  const [menu, setMenu] = useState<Menu>()
  const client = useApolloClient()
  const [disclaimers, setDisclaimers] = useState<string[]>()
  const [errorOccurred, setErrorOccurred] = useState<boolean>()
  const [storeNumber, setStoreNumber] = useState<number>()
  const [serviceMethod, setServiceMethod] = useState<BffContext.ServiceMethods>()
  const [serviceTime, setServiceTime] = useState<string | null | undefined>(undefined)
  const { storedValue: storedMenuItemsByCode, setValue: setStoredMenuItemsByCode } = useLocalStorage<ItemsByCode>({
    key: 'menuItems',
    compress: true,
  })
  const { storedValue: storedDimensionMediaByCode, setValue: setStoredDimensionMediaByCode } =
    useLocalStorage<DimensionMediaByCode>({
      key: 'dimensionMedia',
      compress: true,
    })
  const { storedValue: storedIngredientMediaByCode, setValue: setStoredIngredientMediaByCode } =
    useLocalStorage<IngredientMediaByCode>({
      key: 'ingredientMedia',
      compress: true,
    })
  const [itemsByCode, setItemsByCode] = useState<ItemsByCode>(storedMenuItemsByCode)
  const { language: applicationLanguage } = useLanguages()
  const { fullMenu, menuPageHeaders, hasError, fetchData, refetchData, loading } = useMenuData()
  const [halfHalfMenuWorkaround] = useFeatures('HalfHalfMenuWorkaround')
  const { portionMenuItemData } = usePortionMenu(storeNumber!, serviceMethod!, 'PIZZA.HALFNHALF')
  const termsAndConditions = useMemo(() => t('TermsAndConditionsBody'), [applicationLanguage])

  useEffect(() => {
    if (menu) {
      const flattenedItems = flattenMenu(menu.pages)
      setStoredDimensionMediaByCode(flattenDimensionMedia(flattenedItems))
      setStoredIngredientMediaByCode(flattenIngredientMedia(flattenedItems))
      setStoredMenuItemsByCode(flattenedItems)
      setItemsByCode(flattenedItems)
      setCurrentMenuItems(flattenedItems || undefined)
    } else {
      setCurrentMenuItems(undefined)
    }
  }, [menu])

  useEffect(() => {
    if (fullMenu) {
      setMenu(fullMenu)

      if (halfHalfMenuWorkaround && portionMenuItemData) {
        setMenu(addNewSectionIntoTheMenu(fullMenu, portionMenuItemData))
      }

      setDisclaimers(fullMenu.disclaimers)
    }
  }, [fullMenu, portionMenuItemData])

  useEffect(() => {
    if (menu && serviceMethod && storeNumber && serviceTime !== undefined) {
      reset()
      client.resetStore()
    }
  }, [applicationLanguage])

  useEffect(() => {
    setErrorOccurred(hasError)
  }, [hasError])

  const reset = () => {
    setMenu(undefined)
    setDisclaimers(undefined)
    setErrorOccurred(false)
  }

  const fetchMenu = (pickUpOrDelivery: BffContext.ServiceMethods, storeNo: number, time: string | null) => {
    if (pickUpOrDelivery !== serviceMethod || storeNo !== storeNumber || time !== serviceTime) {
      reset()
      setServiceMethod(pickUpOrDelivery)
      setStoreNumber(storeNo)
      setServiceTime(time)
      fetchData({ storeNo, requestTime: time, serviceMethod: pickUpOrDelivery })
    }
  }

  const refetchMenu = () => {
    reset()
    refetchData()
  }

  return (
    <MenuContext.Provider
      value={{
        data: {
          menu,
          itemsByCode,
          dimensionMediaByCode: storedDimensionMediaByCode,
          ingredientMediaByCode: storedIngredientMediaByCode,
          disclaimers,
          termsAndConditions,
          errorOccurred,
          menuPages: menuPageHeaders,
          menuLoading: loading,
        },
        methods: { fetchMenu, refetchMenu },
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export { MenuContext, MenuProvider }
