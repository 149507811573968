import React from 'react'
import { SvgIconProps } from 'res/interfaces/icon.interfaces'
const defaultDimensions = {
  width: 22,
  height: 22,
}
const PortionProduct = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23 11.5027C23 9.92402 22.7005 8.44059 22.1016 7.05243C21.5026 5.66426 20.674 4.44213 19.6159 3.38604C18.5577 2.32994 17.3353 1.50158 15.9486 0.900948C14.5619 0.300316 13.0791 0 11.5 0C9.92093 0 8.43805 0.300316 7.05137 0.900948C5.66469 1.50158 4.44227 2.32994 3.38411 3.38604C2.32595 4.44213 1.4974 5.66426 0.898438 7.05243C0.299479 8.44059 0 9.92402 0 11.5027C0 13.0778 0.299479 14.5603 0.898438 15.9503C1.4974 17.3403 2.32595 18.5633 3.38411 19.6194C4.44227 20.6755 5.66469 21.503 7.05137 22.1018C8.43805 22.7006 9.92093 23 11.5 23C13.0791 23 14.5619 22.7006 15.9486 22.1018C17.3353 21.503 18.5577 20.6746 19.6159 19.6167C20.674 18.5588 21.5026 17.3357 22.1016 15.9476C22.7005 14.5594 23 13.0778 23 11.5027Z'
        fill='#0A7AFF'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.4965 11.3677V20.894C10.1961 20.894 8.97945 20.6527 7.84655 20.17C6.71365 19.6873 5.71867 19.0164 4.86161 18.1574C4.00455 17.2983 3.33269 16.3024 2.84604 15.1695C2.35939 14.0366 2.11606 12.8199 2.11606 11.5196C2.11606 10.2192 2.3584 9.00255 2.84308 7.86965C3.32777 6.73676 3.99864 5.74178 4.8557 4.88471C4.90283 4.83759 4.95038 4.79102 4.99834 4.74502C5.00241 4.74095 5.00649 4.73688 5.01056 4.73281C5.8696 3.87575 6.86556 3.20389 7.99846 2.71723C9.13136 2.23058 10.348 1.98725 11.6484 1.98725C12.9487 1.98725 14.1654 2.2296 15.2983 2.71428C16.4312 3.19896 17.4261 3.86983 18.2832 4.7269C19.1403 5.58396 19.8111 6.57992 20.2958 7.71479C20.7805 8.84966 21.0228 10.0673 21.0228 11.3677L11.4965 11.3677Z'
        fill='white'
      />
    </svg>
  )
}
export { PortionProduct }
