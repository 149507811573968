import { TFunction } from 'react-i18next'
import { DateTime } from 'luxon'

export const formatETA = (
  t: TFunction<'timed-order'>,
  loading: boolean,
  results?: Bff.Orders.OrderEta,
): {
  text: string
  reportLabel: `${number}-${number}` | null
} => {
  if (loading) {
    return {
      text: t('checking', { defaultValue: 'Checking' }),
      reportLabel: null,
    }
  }

  const errorText = t('ETAUnavailable', { defaultValue: 'Sorry, an estimated time is unavailable' })

  if (!results?.min || !results?.max) {
    return {
      text: errorText,
      reportLabel: null,
    }
  }

  const min = DateTime.fromISO(results.min)
  const max = DateTime.fromISO(results.max)

  if (!min.isValid || !max.isValid) {
    return {
      text: errorText,
      reportLabel: null,
    }
  }

  const now = DateTime.local()
  const from = Math.ceil(min.diff(now, 'minutes').minutes)
  const to = Math.ceil(max.diff(now, 'minutes').minutes)

  return {
    text: t('preOrderEtaText', { from, to, defaultValue: 'Approx {{from}} - {{to}} minutes' }),
    reportLabel: `${from}-${to}`,
  }
}
