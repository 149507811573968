import { navigate } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { CountryToggle, KioskLanguageSelector, KioskOrderButtons } from '@dominos/components'
import {
  useApolloCacheManager,
  useFeatures,
  useKiosk,
  useKioskConfigQuery,
  useKioskErrors,
  useReport,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res'
import { staticKioskBgImages } from '@dominos/res/assets/kiosk-images/static-kiosk-bg-images'
import css from './kiosk-container.less'

export const KioskContainer = () => {
  const dispatch = useDispatch()
  const { appStarted, supportedSubServiceMethods } = useKiosk()
  const { reportOrderTime } = useReport()
  const { t } = useTranslation('kiosk')
  const { notifyKioskError } = useKioskErrors()
  const [isKioskEnabled, injectKioskHeaderEnabled] = useFeatures('KioskWebOrdering', 'InjectKioskHeader')
  const { fetchKioskConfig, kioskConfig, loading, called } = useKioskConfigQuery(injectKioskHeaderEnabled)
  const { large: imagePath } = CountryToggle(staticKioskBgImages)

  const [selectedSubServiceMethod, setSelectedSubServiceMethod] = useState<
    Bff.Stores.PickupServiceMethodSubTypes | undefined
  >()

  const validateKioskOrder = (runQuery?: boolean) => {
    if (!isKioskEnabled) {
      notifyKioskError('KIOSK_NOT_ENABLED')
    } else if (runQuery) {
      fetchKioskConfig().then((res) => {
        res.error && notifyKioskError(res.error)
      })
    }
  }

  const onPressInternal = (subServiceMethod: Bff.Stores.PickupServiceMethodSubTypes | undefined) => {
    setSelectedSubServiceMethod(subServiceMethod)
    validateKioskOrder(true)
  }

  useEffect(() => {
    if (appStarted) {
      validateKioskOrder()
    }
  }, [appStarted])

  const { clearCache } = useApolloCacheManager()

  useEffect(() => {
    if (loading || !called || !kioskConfig.storeNo) {
      return
    }

    dispatch(rootActions.appStartedForKioskWeb(kioskConfig))
    dispatch(rootActions.selectServiceMethodSubType(selectedSubServiceMethod))
    reportOrderTime('ASAP')
    clearCache()
    navigate(NavigationConstants.menu, {
      state: { time: null },
    })
  }, [loading, called, kioskConfig])

  return (
    <>
      <div className={css.image} data-testid='kiosk-container-background'>
        <img src={imagePath} alt={t('WelcomeBackgroundAlt', { defaultValue: "Domino's Kiosk Welcome Page" })} />
      </div>
      <div className={css.grid} data-testid='kiosk-container-wrapper'>
        <StaticSvgIcon name='touch' width='max(min(8vh, 8vw), 50px)' height='max(min(10vh, 10vw), 50px)' isUnstyled />
        <div className={css.title}>{t('BeginOrderTitle', { defaultValue: 'Order Here' })}</div>
        <KioskOrderButtons
          testID='kiosk-container'
          onPress={onPressInternal}
          supportedSubServiceMethods={supportedSubServiceMethods}
        />
      </div>
      <div className={css.languageSelector}>
        <KioskLanguageSelector testId='kiosk-container' />
      </div>
    </>
  )
}
