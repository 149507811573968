import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { FeatureFlagType } from '../feature-flag.interface'
import { defaultFeatures, FeatureType } from '../../features/feature-context'

export const getFeatureFlags = (flags: LDFlagSet): FeatureFlagType | undefined => {
  if (!Object.keys(flags).length) return undefined

  return flags as FeatureFlagType
}

export const getDefaultFeatureFlags = (): FeatureFlagType =>
  Object.values(defaultFeatures).reduce(
    (mappedDefaultFeatures, feature) => ({
      ...mappedDefaultFeatures,
      [feature.key as keyof FeatureFlagType]: getFeatureFlagValue(feature),
    }),
    {} as FeatureFlagType,
  )

const getFeatureFlagValue = (feature: FeatureType) => {
  switch (feature.type) {
    case 'string':
      return feature.value as string
    case 'number':
      return parseInt(feature.value) as number
    case 'boolean':
      return (feature.value === 'true') as boolean
    default:
      return JSON.parse(feature.value) as object
  }
}
