import React, { useEffect } from 'react'
import renderClearCustomerPortal, { EnvironmentType, ICreateIframeOptions } from 'ct-myinvoice'
import { useClearTaxAuthToken } from './use-cleartax'
import { useReport } from '@dominos/hooks-and-hocs'

export const useClearTaxCustomerPortalIframe = (iframeContainer: React.RefObject<HTMLDivElement>) => {
  const { authToken, supplierTin, environment, validUntil } = useClearTaxAuthToken()
  const { reportIframeStatus } = useReport()

  const defaultConfig = {
    width: '100%',
    height: '100%',
    title: 'MY Electronic Invoice',
    styles: { width: '100%', height: '100%', border: 'none' },
    environment: 'sandbox',
  }

  const messageHandler = (event: MessageEvent) => {
    if (event.data.status) {
      reportIframeStatus(event.data.status)
    }
  }

  useEffect(() => {
    if (!authToken || !supplierTin || !environment || !validUntil || !iframeContainer) return

    window.addEventListener('message', messageHandler)

    const portal = renderClearCustomerPortal({
      ...defaultConfig,
      token: authToken,
      tin: window.btoa(supplierTin),
      environment: environment as EnvironmentType,
    } as ICreateIframeOptions)
    if (iframeContainer.current?.firstChild) {
      return
    }
    iframeContainer.current?.appendChild(portal)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [authToken, supplierTin, environment, validUntil])

  return {
    iframeContainer,
  }
}
