import { CollapsableTitledCard, Spinner } from '@dominos/components'
import { OrderStatus } from '@dominos/hooks-and-hocs'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './order-eta.less'

interface OrderEtaProps extends Bff.Orders.OrderEta {
  timezone?: string
  status: OrderStatus
  serviceMethod: BffContext.ServiceMethods
}

const etaUnavailablePlaceholder = 'Sorry, an estimated time is unavailable'

const parseTime = (min: string = '', max: string = '', timezone?: string): { from: DateTime; to: DateTime } => {
  const parsedMinIsoTime = DateTime.fromISO(min, { zone: timezone })
  const parsedMaxIsoTime = DateTime.fromISO(max, { zone: timezone })

  return {
    from: parsedMinIsoTime,
    to: parsedMaxIsoTime,
  }
}

const isTimeParsable = (from: DateTime, to: DateTime): boolean => from.isValid && to.isValid

const formatETAText = (
  from: DateTime,
  to: DateTime,
  status: OrderStatus,
  locale: string,
  t = (x: string, option: Record<string, string>) => x,
): string => {
  const timeFormatMin = t('TimeFormatEtaMin', { defaultValue: 'h:mm' })
  const timeFormatMax = t('TimeFormatEtaMax', { defaultValue: 'h:mm a' })
  const timeFormatFuture = t('TimeFormatEtaFuture', { defaultValue: 'h:mm a d LLL' })

  if (isTimeParsable(from, to)) {
    const displayMin = from.setLocale(locale).toFormat(timeFormatMin)
    const displayMax = to.setLocale(locale).toFormat(timeFormatMax)
    const displayFuture = to.setLocale(locale).toFormat(timeFormatFuture)
    const futureOrderStatusList = [OrderStatus.New, OrderStatus.Pending, OrderStatus.SentToStore, OrderStatus.Timed]

    if (futureOrderStatusList.includes(status)) {
      return displayFuture
    }

    if (from.equals(to)) {
      return displayMax
    }

    return `${displayMin} - ${displayMax}`
  }

  return t('ETAUnavailable', { defaultValue: etaUnavailablePlaceholder })
}

const OrderEta = ({ min, max, timezone, status, serviceMethod }: OrderEtaProps) => {
  const { t, i18n } = useTranslation('tracker')
  const [loading, setLoading] = useState<boolean>(true)

  const [displayText, setDisplayText] = useState<string>(
    t('ETAUnavailable', { defaultValue: etaUnavailablePlaceholder }),
  )

  useEffect(() => {
    const { from, to } = parseTime(min, max, timezone)

    if (loading && isTimeParsable(from, to)) {
      setLoading(false)
    }

    setDisplayText(formatETAText(from, to, status, i18n.language, t))
  }, [min, max, status])

  const titleText =
    serviceMethod === 'Pickup'
      ? t('EstimatedPickupTime', { defaultValue: 'Estimated Pickup Time' })
      : t('EstimatedDeliveryTime', { defaultValue: 'Estimated Delivery Time' })

  return (
    <CollapsableTitledCard testID='order-eta' primaryTitle={titleText} style={{ marginBottom: '8px' }}>
      <div className={styles.orderEta}>
        {loading ? <Spinner testID={'order-eta-spinner'} color={'#1fc36a'} /> : <p>{displayText}</p>}
      </div>
    </CollapsableTitledCard>
  )
}

export { OrderEta }
