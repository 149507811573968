import { useAuthenticationPopup, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import { AuthenticationButtonGroup, CardHeader, NativeAuthenticationButtonGroup } from '@dominos/components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import css from './register.less'
import { isNativeApp, LoginSceneMessage, postWebViewMessage } from '@dominos/business/functions'
import { NavigationConstants } from '@dominos/navigation'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'

interface RegisterProps extends BaseProps {
  inline?: boolean
  onLogin?: () => void
  onRegister?: () => void
  onAdditional?: (props?: Partial<CreateAccountSceneProps>) => void
  onPopupBlocked?: () => void
}

export const AccountRegister = ({
  testID,
  onLogin,
  onRegister,
  onAdditional,
  onPopupBlocked,
  inline,
}: RegisterProps) => {
  const { t } = useTranslation('login')
  const { triggerAction, data } = useQuickUniversalAccountComponent()
  const { hidePopup } = useAuthenticationPopup()

  const handleSuccess = () => hidePopup('SignInSuccess')

  const handleCreateAccount = () =>
    onRegister ? onRegister() : triggerAction('next', { ...data, socialCustomer: undefined })

  const handleAlternativeOption = () => (onLogin ? onLogin() : triggerAction('alternative'))

  const handleAdditionalDetails = (socialCustomer: Partial<CreateAccountSceneProps>) =>
    onAdditional ? onAdditional(socialCustomer) : triggerAction('additional', { ...data, socialCustomer })

  const handlePopupBlocked = () => (onPopupBlocked ? onPopupBlocked() : triggerAction('warning'))

  const isNative = isNativeApp()

  useEffect(() => {
    if (isNative) {
      postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppCreateAccount })
    }
  }, [])

  return (
    <div data-testid={testID}>
      <CardHeader
        testID={`${testID}.header`}
        title={t('SignUpTitle', { defaultValue: `Create Account` })}
        inline={inline}
      />
      <div data-testid={`${testID}.section`} className={css.section}>
        <div data-testid={`${testID}.description`}>
          {t('SignUpDescription', {
            defaultValue: `Unlock faster ordering and easier checkout with a Domino's account`,
          })}
        </div>
      </div>
      <div data-testid={`${testID}.section`} className={css.section}>
        {isNative ? (
          <NativeAuthenticationButtonGroup
            testID={`${testID}.native-auth-button-group`}
            onEmail={handleCreateAccount}
            onRegister={handleAdditionalDetails}
          />
        ) : (
          <AuthenticationButtonGroup
            testID={`${testID}.social`}
            onEmail={handleCreateAccount}
            onComplete={handleSuccess}
            onRegister={handleAdditionalDetails}
            onPopupBlocked={handlePopupBlocked}
          />
        )}
        <div data-testid={`${testID}.alternative`} className={css.alternative}>
          {t('SignUpAlternativeLabel', { defaultValue: 'Already got an account?' })}
          <a data-testid={`${testID}.link`} onClick={handleAlternativeOption}>
            {t('SignUpAlternativeButtonText', { defaultValue: `Log in` })}
          </a>
        </div>
      </div>
    </div>
  )
}
