import React from 'react'

import { FlatList } from '@dominos/components/'

import css from './alternative-store-list.less'
import { toPascalCase } from '@dominos/business/functions/text'
import { StoreItemRenderProps, StoreListProps } from './alternative-store-list.interface'

const keyExtractor = (item: Bff.Stores.StoreDistance) => `${item.storeNo}`

export const AlternativeStoreList = (props: StoreListProps) => {
  const renderItem = (info: Bff.Stores.StoreDistance, key: string) => (
    <StoreItem key={key} store={info} onPress={props.onPress} selected={props.initalStoreSelected == info.storeNo} />
  )

  return (
    <div data-testid={props.testID}>
      <FlatList data={props.stores} renderItem={renderItem} keyExtractor={keyExtractor} />
    </div>
  )
}

const StoreItem = ({ store, onPress, selected }: StoreItemRenderProps) => (
  <div className={css.storeContainer}>
    <input
      type='radio'
      value={store.storeNo}
      name='alternative-store'
      role='button'
      onClick={() => onPress(store)}
      id={store.storeNo.toString()}
      defaultChecked={selected}
    />
    <label htmlFor={store.storeNo.toString()} className={css.textContainer}>
      {toPascalCase(store.storeName || '')}
    </label>
  </div>
)
