import { clearPersistedState } from '@dominos/hooks-and-hocs/application/base-persistant-config'
import { NativeAppFosData, NativeAppStartupData } from 'packages/interfaces'
import { idTokenUtils } from '@dominos/business/functions/customer/id-token-utils'
import { decode } from 'js-base64'

export const isNativeApp = (): boolean => !!window.nativeAppStartupData

type Subset<T, U extends T> = U

export type WebviewHandoverData =
  | {
      nativeAppStartupData?: NativeAppStartupData
      nativeAppLoadedAt?: string
      nativeAppPlatform?: Subset<BffContext.ApplicationTypes, 'iOSApp' | 'AndroidApp'>
      OTExternalConsent?: object
      fosData?: NativeAppFosData
      nativeAppLoggedIn?: boolean
      nativeAppAuthToken?: string
      nativeAppVersion?: string
      nativeAppTrackingEnabled?: boolean
      nativeAppLaunchDarklyId?: string
    }
  | undefined

export const initWebviewData = () => {
  const uriHash = window.location.hash
  const uriData: WebviewHandoverData = decodeWebviewData(uriHash)

  if (uriData?.nativeAppStartupData) {
    window.nativeAppStartupData = uriData?.nativeAppStartupData
  }

  if (uriData?.nativeAppLoadedAt) {
    window.nativeAppLoadedAt = uriData?.nativeAppLoadedAt
  }

  if (uriData?.nativeAppVersion) {
    window.nativeAppVersion = uriData?.nativeAppVersion
  }

  if (uriData?.nativeAppPlatform) {
    window.nativeAppPlatform = uriData?.nativeAppPlatform
  }

  if (uriData?.OTExternalConsent) {
    window.OTExternalConsent = uriData?.OTExternalConsent
  }

  if (uriData?.nativeAppTrackingEnabled) {
    window.nativeAppTrackingEnabled = uriData?.nativeAppTrackingEnabled
  }

  if (uriData?.fosData) {
    window.nativeAppFosData = uriData?.fosData
  }

  if (uriData?.nativeAppLaunchDarklyId) {
    window.nativeAppLaunchDarklyId = uriData?.nativeAppLaunchDarklyId
  }

  if (!isNativeApp()) {
    return
  }

  // Prevents a known bug in iOS webview when going back to scrolled page
  // See: https://github.com/react-native-webview/react-native-webview/issues/3124
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
  }

  clearPersistedState()
  const { deleteToken, saveToken } = idTokenUtils()

  if (uriData?.nativeAppLoggedIn === false || window?.nativeAppLoggedIn === false) {
    deleteToken()

    return
  }

  const authToken = uriData?.nativeAppAuthToken || window?.nativeAppAuthToken
  if (authToken) {
    saveToken(authToken)

    return
  }

  // nativeAppAuthToken is present but empty string
  if (uriData?.nativeAppAuthToken !== undefined || window?.nativeAppAuthToken !== undefined) {
    deleteToken()
  }
}

const decodeWebviewData = (uriHash: string): WebviewHandoverData => {
  try {
    const encodedData = uriHash.slice(1)

    return JSON.parse(decode(encodedData))
  } catch {
    return {}
  }
}
