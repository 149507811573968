import { allergensMockData, nutritionalsMockData } from '@dominos/business/functions/nutritional/index'
import {
  CountryToggle,
  ProductDescription,
  ProductEditor,
  ProductEditorContentWrapper,
  ProductImage,
  useProductContext,
} from '@dominos/components'
import { ProductNutritionalCard } from '@dominos/components/product/product-nutritional-feature-product'
import {
  ProductAllergen,
  ProductNutritionals,
} from '@dominos/components/product/product-nutritional-feature-product/product-nutritional.interface'
import { useBreakpoints, useFeatures, useKiosk } from '@dominos/hooks-and-hocs'
import React from 'react'
import { BaseProductEditorProps } from './base-product-editor.interface'
import css from './base-product-editor.less'

export const BaseProductEditor = ({ testID, onDismiss, children, t }: BaseProductEditorProps) => {
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder

  const [nutritionalEnabled, nutritionalInfoButtonEnabled] = useFeatures(
    'nutritionals',
    'ProductNutritionalInformationButton',
  )
  const { productData } = useProductContext()

  const showContainsAllergensOnly = CountryToggle({
    FR: true,
    default: false,
  })

  const { productNutritionals, productAllergens } = nutritionalEnabled
    ? {
        productNutritionals: nutritionalsMockData as ProductNutritionals,
        productAllergens: allergensMockData as ProductAllergen[],
      }
    : { productNutritionals: null, productAllergens: [] }

  return (
    <ProductEditor
      testID={`${testID}.product-editor`}
      onDismiss={onDismiss}
      showBackButton={!!isMobileLayout}
      showCloseButton={!isMobileLayout}
    >
      <ProductImage testID={testID} uri={productData?.media?.largeImage?.uri ?? ''}></ProductImage>
      <ProductEditorContentWrapper testID={`${testID}.basic-product-card.children`} className={css.baseEditorContent}>
        <ProductDescription testID={testID} media={productData?.media} t={t}></ProductDescription>
        {children}
        <ProductNutritionalCard
          nutritionals={productNutritionals}
          allergens={productAllergens}
          t={t}
          showContainsAllergensOnly={showContainsAllergensOnly}
          testID='nutritional-card'
          showInfoButton={nutritionalInfoButtonEnabled}
        />
      </ProductEditorContentWrapper>
    </ProductEditor>
  )
}
