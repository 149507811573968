import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import { MoreInfoAccessoryProps } from './more-info-accessory.interface'

import css from './more-info-accessory.less'

export const MoreInfoAccessory = (props: MoreInfoAccessoryProps) => (
  <div
    data-testid={props.testID}
    role={'button'}
    aria-label={props.label}
    aria-controls={props.id}
    className={css.accessory}
    onClick={props.onClick}
  >
    <StaticSvgIcon name={'question'} width={21} height={21} isUnstyled />
  </div>
)
