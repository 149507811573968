import { CardHeader } from '@dominos/components'
import { useAuthenticationPopup, useFos, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import { EmailLogin } from '@dominos/components/login/email-login'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import {
  FosEventInfoType,
  FosInteractionEventParameter,
  FosInteractionEventParameterConstants,
  FosInteractionEventType,
} from 'olo-feature-fos'
import css from './sign-in-email.less'
import { isNativeApp } from '@dominos/business/functions'

export const SignInEmail = ({ testID }: BaseProps) => {
  const { t } = useTranslation('login')
  const { sendFosEvent } = useFos()
  const { pathname } = useLocation()
  const isFromNativeApp = isNativeApp()
  const [keepMeLoggedIn, toggleKeepMeLoggedIn] = useState<boolean>(false)
  const { triggerAction, previousStep, hasPrevious } = useQuickUniversalAccountComponent()
  const { hidePopup } = useAuthenticationPopup()

  const handleAlternativeOption = useCallback(() => triggerAction('alternative'), [])

  const handleSuccess = () => hidePopup('SignInSuccess')

  const handlePasswordReset = useCallback(() => {
    const additionalInfo = {
      [FosInteractionEventParameter.Popup]: FosInteractionEventParameterConstants.ForgottenPasswordPopup,
    }

    sendFosEvent({
      additionalInfo,
      type: FosEventInfoType.InteractionEvent,
      locationPath: pathname,
      eventType: FosInteractionEventType.ForgotPasswordButtonClicked,
    })

    triggerAction('forgot')
  }, [])

  const enableLongLived = isFromNativeApp || keepMeLoggedIn

  return (
    <div data-testid={testID}>
      <CardHeader
        testID={`${testID}.header`}
        title={t('SignInEmailTitle', { defaultValue: `Login` })}
        onAction={hasPrevious ? () => previousStep() : undefined}
      />
      <div data-testid={`${testID}.content`} className={css.content}>
        <div data-testid={`${testID}.alternative`} className={css.alternative}>
          {t('SignInAlternativeLabel', { defaultValue: `Don't have an account?` })}
          <a data-testid={`${testID}.link`} onClick={handleAlternativeOption}>
            {t('SignInAlternativeButtonText', { defaultValue: `Sign up` })}
          </a>
        </div>
        <EmailLogin
          authenticationSource={'UniversalAuth'}
          onReset={handlePasswordReset}
          onSuccess={handleSuccess}
          keepMeLoggedIn={keepMeLoggedIn}
          toggleKeepMeLoggedIn={toggleKeepMeLoggedIn}
          enableLongLived={enableLongLived}
        />
      </div>
    </div>
  )
}
