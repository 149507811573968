import React from 'react'
import { useTranslation } from 'react-i18next'

import { MDTargetBlankAnchor, Modal } from '@dominos/components'
import { useCountryCode } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import Markdown from 'markdown-to-jsx'

import { getRoundUpForCharityImages } from '../get-round-up-for-charity-images'
import css from './round-up-for-charity-popup.less'

interface RoundUpForCharityPopupProps {
  testID: string
  onClose: () => void
}

const iconWidth = 21
const iconHeight = 21
const icon = 'cross'

export const RoundUpForCharityPopup = ({ testID, onClose }: RoundUpForCharityPopupProps) => {
  const { t } = useTranslation('checkout')
  const countryCode = useCountryCode()

  const contentTestID = `${testID}.popup`

  const banner = getRoundUpForCharityImages(countryCode, 'infoBanner')
  const images = getRoundUpForCharityImages(countryCode, 'infoLogo')

  return (
    <Modal testID='round-up-for-charity-popup' onModalClose={onClose}>
      <div data-testid={contentTestID} className={css.cardContainer}>
        <div data-testid={`${contentTestID}.close-button`} role='close' className={css.closeButton} onClick={onClose}>
          <div role='button'>
            <StaticSvgIcon name={icon} width={iconWidth} height={iconHeight} isUnstyled />
          </div>
        </div>
        <div className={css.contentContainer}>
          {banner && (
            <div data-testid={`${contentTestID}.banner`} className={css.contentBanner}>
              <img src={banner} />
            </div>
          )}
          <h4 className={css.contentHeader}>{t('roundUpForCharityPopupTitle')}</h4>
          <Markdown className={css.contentBody} options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
            {t(`roundUpForCharityPopupBody`)}
          </Markdown>
          {images && images instanceof Array && (
            <div data-testid={`${contentTestID}.footer`} className={css.contentFooter}>
              {images.map((image: string, index: number) => (
                <img key={index} src={image} />
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
