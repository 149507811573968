import React from 'react'
import { PortionTileView } from './portion-tile-view'
import { createPortionTileViewModel, PortionTileProps } from './portion-tile-view.interface'

const PortionTile = (props: PortionTileProps): JSX.Element => (
  <PortionTileView
    viewModel={createPortionTileViewModel({ ...props })}
    onSelectChange={props.onSelectChange}
    onReset={props.onReset}
    onCustomise={props.onCustomise}
  />
)

export { PortionTile }
