import { useCurrentOrderDetails, usePaymentsAvailableAtStore } from '@dominos/hooks-and-hocs'
import React from 'react'

const ExpressCheckout = () => {
  const { basketHeaderData } = useCurrentOrderDetails()
  usePaymentsAvailableAtStore(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
    basketHeaderData?.serviceMethodSubType,
    basketHeaderData?.time ?? undefined,
  )

  return <></>
}

export { ExpressCheckout }
